<template>
  <div class="payment">
    <div v-if="(cross_sold_product_group && has_cross_store_payouts_enabled) || !cross_sold_product_group" class="payment__errors">
      <p v-if="!has_user_shipping_option && wait_for_shipping_option(selected_group)">
        {{ translations.you_need_to_choose_shipping_method || "You need to choose a shipping method." }}
      </p>
      <p v-if="countries_dont_match">
        {{ translations.country_doesnt_match_method || "Selected country doesnt match chosen shipping country." }}
      </p>
    </div>

    <transition name="slide">
      <div v-if="show_form">
        <user_form
          @cancel_payment_method="show_form = false"
          @user_data_updated="update_user_data"
          @data_valid="valid => user_data_valid = valid"
        />
        <button
          class="button button--wide button--full-width"
          :disabled="!user_data_valid"
          @click="payment_authorization({}, null, false)"
        >{{ translations.finish_order || $translate("finish_order") || "Finish order" }}</button>
      </div>
    </transition>

    <div v-show="!show_form">
      <div v-if="!show_payment_buttons && !show_paypal_message" class="payment__disabled" />
      <div v-if="!is_admin_imported && project_config.use_bank_transfer && !cross_sold_product_group" class="payment__other-payment-method" @click="() => {
        payment_method = payment_methods.bank_transfer
        show_form = true
      }">
        {{ translations.pay_via_bank_transfer || "Pay via bank transfer" }}
      </div>
      <div
        v-if="!is_admin_imported && project_config.use_cash_on_pickup && !cross_sold_product_group"
        class="payment__other-payment-method"
        :class="{ 'payment__other-payment-method--disabled': !shipping_option || !shipping_option.enable_on_pickup_payment }"
        @click="() => {
          if (shipping_option && shipping_option.enable_on_pickup_payment) {
            payment_method = payment_methods.on_pickup
            show_form = true
          }
        }"
      >
        {{ translations.pay_via_cash || "Pay via cash/card on pickup" }}
      </div>
      <div
        v-if="use_shopify_data"
        class="payment__shopify-button button button--green"
        @click="open_shopify_checkout_window"
      >{{ translations.shopify_buy_button || $translate("basket.shopify_buy_button") }}</div>
      <div v-else-if="!is_admin_imported" class="payment__paypal">
        <div
          v-if="(cross_sold_product_group && has_cross_store_payouts_enabled) || !cross_sold_product_group"
          id="paypal-button-container"
          :class="{ 'payment__buttons' : !show_payment_buttons }"
          ref="paypal_buttons"
        />
        <div v-if="loading_paypal_buttons" class="payment__loading">
          <Loader />
        </div>
        <div v-if="show_paypal_message || !has_cross_store_payouts_enabled">
          <div v-if="cross_sold_product_group">
            {{ translations.products_from_cross_store_are_unavailable }}
          </div>
          <div v-else-if="is_marketplace_environment">
            {{ $translate("basket.paypal_not_connected") }}
            <div
              v-if="selected_group[0].project_domain"
              v-html="$translate(
                'basket.contact_message',
                { link: `<a href='https://${selected_group[0].project_domain}/contact-us' target='_blank'>HERE</a>`
              })"
            />
          </div>
          <div v-else-if="!project_config.use_cash_on_pickup && !project_config.use_bank_transfer">
            Owner of this website has not connected a payment gateway yet, if you wish to finish your checkout process, please contact
            them <router-link class="payment__link" to="/contact-us">HERE</router-link>.
          </div>
        </div>
      </div>
      <img v-if="show_paypal_payment_image" src="/static/icons/secure_checkout.png" alt="Secure card payments icon">
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex"
import Loader from "@/applications/Shared/components/loader/index.vue"
import { CLIENT_STORE } from "../../../../Client/constants/other"
import get_paypal_config from "../../../../Client/methods/get_paypal_config"
import countries from "../../../../../data/countries"
import get_shipping_rates from "../../../../Client/methods/get_shipping_rates"
import validate_basket from "../../../../Client/methods/validate_basket"
import calculate_shipping_rate from "../../../../Client/methods/calculate_shipping_rate"
import { MARKETPLACE } from "../../../../Marketplace/constants/other"
import un_bind from "../../../methods/un_bind"
import { UPDATE_GLOBAL_LOADER } from "../../../../../store"
import { countries_requiring_states } from "../../../../../data/other_constants"
import { current_store_key, other_shipping_methods, payment_methods, SHARED_STORE } from "../../../constants/other"
import {
  DOES_BASKET_REQUIRE_SHIPPING, GET_BASKET_PRICE, GET_PRODUCT_PRICE, GET_TOTAL_PRICE, GET_TOTAL_PRODUCT_DISCOUNTS,
  REMOVE_PURCHASE_ITEM_FROM_BASKET, UPDATE_BASKET_ITEMS, UPDATE_BASKET_GROUPS, UPDATE_SELECTED_BASKET_GROUP,
  UPDATE_ORDER_LINKS
} from "../../../stores/Shared/constants"
import { ga_event_actions, ga_event_categories } from "../../../constants/ga_constants"
import { event_hub } from "../../../../../main"
import user_form from "./user_form"
import { create_order } from "../../../methods/ajax/endpoints"
import { UPDATE_TEMP_ORDER_DATA } from "../../../../Client/stores/Client/constants"
import get_country_by_code from "../../../methods/get_country_by_code"
import { get_ga_instance } from "../../../../../google_tracking"

export default {
  components: {
    Loader,
    user_form
  },
  props: {
    selected_group_currency: {
      type: String,
      required: false,
      default: ""
    },
    selected_group_language: {
      type: String,
      required: false,
      default: ""
    },
    company_info: {
      type: Object,
      required: false,
      default: () => ({})
    },
  },
  data() {
    return {
      success: false,
      error: false,
      countries_dont_match: false,
      missing_shipping_error: false,
      loading_paypal_buttons: true,
      show_paypal_message: false,
      show_form: false,
      user_data: {},
      user_data_valid: false,
      payment_method: "",
      payment_methods
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "is_production",
      "translations",
      "shopify_checkout",
      "use_shopify_data",
      "user_settings",
      "selected_language",
      "project_config",
      "shipping_options",
      "shipping_option",
      "is_admin_imported"
    ]),
    ...mapGetters(SHARED_STORE, {
      products_price: GET_PRODUCT_PRICE,
      total_price: GET_TOTAL_PRICE,
      price_excluding_shipping: GET_BASKET_PRICE,
      wait_for_shipping_option: DOES_BASKET_REQUIRE_SHIPPING,
      total_product_discounts: GET_TOTAL_PRODUCT_DISCOUNTS
    }),
    ...mapState(MARKETPLACE, [
      "marketplace_currency",
    ]),
    ...mapState([
      "is_marketplace_environment",
      "user"
    ]),
    ...mapState(SHARED_STORE, [
      "discount_code",
      "basket_items",
      "selected_group",
      "basket_product_groups"
    ]),
    show_paypal_payment_image() {
      return !this.use_shopify_data && !this.show_paypal_message && this.has_cross_store_payouts_enabled
    },
    cross_sold_product_group() {
      return this.selected_group?.every(({ project_id }) => project_id) && !this.is_marketplace_environment
    },
    currency_code() {
      return this.is_marketplace_environment ? (this.selected_group_currency || this.marketplace_currency) : this.translations.currency_code
    },
    selected_group_store_info() {
      return this.selected_group ? this.selected_group.find(({ paypal_config }) => paypal_config) : { taxes: {} }
    },
    selected_group_paypal_config() {
      return this.selected_group_store_info?.paypal_config?.client_id ?
        { data: this.selected_group_store_info.paypal_config } : null
    },
    product_group_shipping_methods() {
      return this.selected_group?.find(({ shipping_options } = {}) => shipping_options)?.shipping_options
    },
    options() {
      return this.product_group_shipping_methods || this.shipping_options || {}
    },
    shipping_setting() {
      return this.options.settings || {}
    },
    custom_shipping_methods() {
      return this.options.custom_methods
    },
    discounts() {
      return this.options.shipping_discounts || []
    },
    language() {
      return this.selected_group_language || this.selected_language
    },
    rate_discount_percentage() {
      const applicable_discounts = this.discounts
        .filter(({ translations }) =>
          Number(translations[this.language].minimum_basket_price)
          <=
          Number(this.products_price(this.selected_group, this.language))
        )
        .map(({ discount_percentage }) => Number(discount_percentage))

      return applicable_discounts.sort((discount_a, discount_b) => discount_b - discount_a)[0] || 0
    },
    use_custom_shipping_methods() {
      return this.options.use_custom_methods || this.options.use_custom_methods === undefined
    },
    allowed_countries() {
      if (this.use_custom_shipping_methods) {
        const do_all_options_have_allowed_countries = this.custom_shipping_methods.every(({ allowed_countries }) => allowed_countries)

        return do_all_options_have_allowed_countries ? Array.from(
          new Set(
            this.custom_shipping_methods
              .flatMap(({ allowed_countries }) => allowed_countries)
              .map(country => countries.find(({ code }) => code === country))
          )
        ) : countries
      }
      else return this.shipping_setting.allowed_countries || countries
    },
    has_user_shipping_option() {
      return !!Object.keys(this.shipping_option || {}).length
    },
    show_payment_buttons() {
      return !this.wait_for_shipping_option(this.selected_group) || this.has_user_shipping_option
    },
    maximum_shipping_discount() {
      return this.shipping_setting.maximum_shipping_discount || 0
    },
    has_cross_store_payouts_enabled() {
      return typeof this.selected_group?.[0]?.has_enabled_payouts === "boolean" ?
        this.selected_group[0].has_enabled_payouts : true
    }
  },
  watch: {
    selected_group: {
      handler(new_val, old_val) {
        const new_product_ids = new_val?.map(({ id }) => id).join()
        const old_product_ids = old_val?.map(({ id }) => id).join()

        if (new_product_ids !== old_product_ids) this.load_paypal()
      },
      deep: true
    },
    currency_code() {
      this.load_paypal()
    }
  },
  mounted() {
    this.load_paypal()
  },
  methods: {
    ...mapMutations(SHARED_STORE, {
      update_basket_items: UPDATE_BASKET_ITEMS,
      remove_purchased_item_from_basket: REMOVE_PURCHASE_ITEM_FROM_BASKET,
      update_groups: UPDATE_BASKET_GROUPS,
      update_selected_group: UPDATE_SELECTED_BASKET_GROUP,
      update_order_links: UPDATE_ORDER_LINKS,
    }),
    ...mapMutations(CLIENT_STORE, {
      update_temp_order_data: UPDATE_TEMP_ORDER_DATA
    }),
    ...mapMutations({
      update_global_loader: UPDATE_GLOBAL_LOADER
    }),
    update_user_data(data) {
      this.user_data = data
    },
    async load_paypal() {
      if (!this.has_cross_store_payouts_enabled) return this.loading_paypal_buttons = false
      if (!this.$refs.paypal_buttons || this.is_admin_imported) return

      this.$refs.paypal_buttons.innerHTML = ""
      this.loading_paypal_buttons = true
      this.show_paypal_message = false

      if (
        (!this.use_shopify_data && this.project_config.use_paypal_payment_gateway) ||
        ((this.is_marketplace_environment || this.cross_sold_product_group) && this.selected_group_paypal_config?.data)
      ) {
        const { data: { client_id, mode = "production" } = {} } = (
          this.selected_group_store_info?.paypal_config ? this.selected_group_paypal_config : await get_paypal_config()
        ) || {}

        if (client_id) {
          await this.$loadScript(`https://www.paypal.com/sdk/js?client-id=${client_id}&currency=${this.currency_code}`)

          paypal.Buttons({
            env: this.is_production ? mode : "sandbox",
            createOrder: this.create_payment,
            onApprove: this.payment_authorization,
            onShippingChange: this.user_updated_shipping_info,
            style: {
              size: "responsive",
              color: "black",
              shape: "rect",
              label: "checkout",
              fundingicons: true
            }
          }).render("#paypal-button-container");
        } else this.show_paypal_message = true

        this.loading_paypal_buttons = false
      }
      else if (this.use_paypal_payment_gateway === undefined) {
        this.show_paypal_message = true
        this.loading_paypal_buttons = false
      }
    },
    async get_correct_rate(address) {
      let final_rate

      if (this.use_custom_shipping_methods) {
        const available_shipping_methods = this.custom_shipping_methods.filter(
          ({ allowed_countries = countries.map(({ code }) => code) }) => allowed_countries.includes(address.country_code)
        )
        final_rate = available_shipping_methods.find(({ id }) => id === this.shipping_option.rate_id) || available_shipping_methods[0]
      } else {
        const rates = await get_shipping_rates(address, this.basket_items, this.currency_code)

        final_rate = this.find_closest_rate(rates)
      }

      if (!final_rate) return

      const price = final_rate.translations ? final_rate.translations[this.language].price : (final_rate.price || final_rate.amount_local)

      return {
        ...calculate_shipping_rate(price, this.maximum_shipping_discount, this.rate_discount_percentage),
        initial_price: price,
        shipment_id: final_rate.shipment,
        rate_id: final_rate.object_id || final_rate.id,
        country_code: address.country_code,
        title: final_rate.provider || final_rate.translations[this.language].title,
        state: address.state,
        is_custom: !!this.use_custom_shipping_methods,
        estimated_days: final_rate.estimated_days,
      }
    },
    find_closest_rate(rates) {
      const chosen_price = Number(this.shipping_option.price) || 0
      const chosen_days = this.shipping_option.estimated_days || 0
      const acceptable_rate = rates[0] // rates are already sorted by price
      const ideal_rate = rates
        .filter(({ price, estimated_days }) =>
          Number(price) <= chosen_price && // Check that rate is at most same price as new one
          (estimated_days ? estimated_days <= (chosen_days + 1) : true // Check that if chosen had estimated days, the new one takes at most as many + 1
        ))
        .sort(({ estimated_days }, { estimated_days: estimated_days_second }) => estimated_days - estimated_days_second)[0]

      return !this.shipping_option.price ? acceptable_rate : (ideal_rate || acceptable_rate)
    },
    async user_updated_shipping_info(data, actions) {
      if (
        this.wait_for_shipping_option(this.selected_group) &&
        data.shipping_address.country_code !== this.shipping_option.country_code &&
        (countries_requiring_states.includes(data.shipping_address.country_code) ? data.shipping_address.state !== this.shipping_option.state : false)
      ) {
        if (!this.allowed_countries.find(({ code }) => data.shipping_address.country_code === code)) return actions.reject()

        const final_rate = await this.get_correct_rate({
          country_code: data.shipping_address.country_code,
          state: data.shipping_address.state,
          city: data.shipping_address.city,
          zip: data.shipping_address.postal_code,
        }) // Needing to re-evaluate rate based on new address info

        if (!final_rate) return actions.reject()

        const total_price = (
          parseFloat(this.price_excluding_shipping(this.selected_group, this.selected_group_store_info.taxes[this.language], this.language)) +
          parseFloat(final_rate.price)
        ).toFixed(2)
        
        return this.total_price(this.selected_group, this.selected_group_store_info.taxes[this.language], this.language) == total_price ?
          actions.resolve() :
          actions.order.patch([
            {
              op: "replace",
              path: "/purchase_units/@reference_id=='default'/amount",
              value: {
                currency_code: this.currency_code,
                value: total_price,
                breakdown: {
                  item_total: {
                      currency_code: this.currency_code,
                      value: parseFloat(this.price_excluding_shipping(this.selected_group, this.selected_group_store_info.taxes[this.language], this.language))
                  },
                  shipping: {
                      currency_code: this.currency_code,
                      value: parseFloat(final_rate.price)
                  }
                }
              }
            }
          ]);
      }

      return actions.resolve()
    },
    open_shopify_checkout_window() {
      // FB event
      if (window.fbq) fbq("track", "Purchase", {
        value: Number(this.products_price(this.selected_group, this.language)),
        currency: this.currency_code,
      });

      get_ga_instance().then((ga) => {
        ga.track(ga_event_actions.purchase, {
          amount:  Number(this.products_price(this.selected_group, this.language)),
          currency: this.currency_code,
        })
      })

      window.open(this.shopify_checkout.webUrl, "_self")
    },
    async create_payment(_, actions) {
      const { data: validated_basket } = await validate_basket(this.basket_items)

      if (
        this.products_price(this.selected_group, this.language) !==
        this.products_price(validated_basket, this.language)
      ) this.update_basket_items(validated_basket)

      return actions.order.create({
        purchase_units: [{
          amount: { value: this.total_price(this.selected_group, this.selected_group_store_info.taxes[this.language], this.language) }
        }]
      })
    },
    async payment_authorization(data = {}, actions, is_paypal_order = true) {
      this.update_global_loader(true)
      let paypal_data
      let shipping_method = {}

      if (is_paypal_order) paypal_data = await actions.order.capture().catch(err => console.log(err))

      const shipping_info = is_paypal_order ? paypal_data.purchase_units[0].shipping : this.user_data
      const [first_name, ...last_name] = is_paypal_order ?
        shipping_info.name.full_name.split(" ") :
        [shipping_info.first_name, shipping_info.last_name]

      if (this.shipping_option.type === other_shipping_methods.zasilkovna) shipping_method = this.shipping_option
      // Needing to re-evaluate rate based on new address info
      else if (this.wait_for_shipping_option(this.selected_group)) shipping_method = await this.get_correct_rate({ 
        street1: is_paypal_order ? shipping_info.address.address_line_1 : shipping_info.address1,
        country_code: is_paypal_order ? shipping_info.address.country_code : shipping_info.country.code,
        state: is_paypal_order ? shipping_info.address.admin_area_1 : shipping_info.state,
        city: is_paypal_order ? shipping_info.address.admin_area_2 : shipping_info.city,
        zip: is_paypal_order ? shipping_info.address.postal_code : shipping_info.zip,
        name: `${first_name} ${last_name.join(" ")}`,
        phone: shipping_info.phone || "unknown"
      })

      if (!shipping_method) return this.update_global_loader(false)

      const user_data = {
        first_name,
        last_name: last_name.join(" "),
        address1: is_paypal_order ? shipping_info.address.address_line_1 : shipping_info.address1,
        address2: is_paypal_order ? shipping_info.address.address_line_2 : shipping_info.address2,
        zip: is_paypal_order ? shipping_info.address.postal_code : shipping_info.zip,
        city: is_paypal_order ? shipping_info.address.admin_area_2 : shipping_info.city,
        state: is_paypal_order ? shipping_info.address.admin_area_1 : shipping_info.state,
        country: is_paypal_order ? get_country_by_code(shipping_info.address.country_code).name : shipping_info.country.name,
        phone: shipping_info.phone || "unknown",
        email: is_paypal_order ? paypal_data.payer.email_address : shipping_info.email,
        billing_data: {
          ...(is_paypal_order ? {} : shipping_info?.billing_data),
          first_name: shipping_info?.billing_data?.first_name || paypal_data.payer.name.given_name,
          last_name: shipping_info?.billing_data?.last_name || paypal_data.payer.name.surname,
          email: shipping_info?.billing_data?.email || paypal_data.payer.email_address,
          country: shipping_info?.country?.name || get_country_by_code(paypal_data.payer.address.country_code).name,
          ...this.company_info
        }
      }
      const order_data = {
        paymentID: data.paymentID,
        orderID: data.orderID,
        user_data,
        paypal_data,
        total_line_items_price: this.products_price(this.selected_group, this.language),
        subtotal_price: this.price_excluding_shipping(
          this.selected_group,
          this.translations?.tax_percentage || this.selected_group_store_info?.taxes?.[this.language],
          this.language
        ),
        total_price: is_paypal_order ? Number(paypal_data.purchase_units[0].amount.value) : this.total_price(
          this.selected_group,
          this.translations?.tax_percentage || this.selected_group_store_info?.taxes?.[this.language],
          this.language
        ),
        currency: is_paypal_order ? paypal_data.purchase_units[0].amount.currency_code : this.currency_code,
        taxes: this.translations.tax_percentage || this.selected_group_store_info?.taxes?.[this.language],
        total_tax: this.price_excluding_shipping(
          this.selected_group,
          this.selected_group_store_info?.taxes?.[this.language],
          this.language
        ) - this.products_price(this.selected_group, this.language),
        user_fingerprint: this.user_settings.fingerprint,
        selected_language: this.language,
        tags: [], // TODO add tags to orders
        base_url: window.location.origin,
        payment_method: is_paypal_order ? payment_methods.paypal : this.payment_method,
        discount_codes: (this.discount_code && this.discount_code.type) ? [this.discount_code] : [],
        total_discounts: this.total_product_discounts(this.selected_group, this.language) +
          (Number(shipping_method.initial_price) -
          Number(shipping_method.price)),
        products_data: this.selected_group || this.basket_items,
        shipping_method,
        store_address: {
          company: this.options.project_address?.company,
          street1: this.options.project_address?.street1,
          street2: this.options.project_address?.street2,
          zip: this.options.project_address?.zip,
          state: this.options.project_address?.state,
          country: get_country_by_code(this.options.project_address?.country)?.name,
          phone: this.options.project_address?.phone,
        }
      }

      this.create_new_order(order_data)
    },
    async create_new_order(data) {
      try {
        let order_link
        const store_id = this.selected_group ? this.selected_group[0].project_id : null
        const { data: order_response } = await create_order({
          ...data,
          store_id,
          user_id: this.user?.info?.uid,
          is_cross_order: this.cross_sold_product_group
        })

        // FB event
        if (window.fbq) fbq("track", "Purchase", {
          value: Number(this.products_price(this.selected_group, this.language)),
          currency: this.currency_code,
        });

        // GA event
        event({
          eventCategory: ga_event_categories.basket,
          eventAction: ga_event_actions.purchase,
          eventLabel: this.currency_code,
          eventValue: Number(this.products_price(this.selected_group, this.language))
        })

        if (this.selected_group) {
          order_link = {
            route: `/order/${order_response.order_id}${store_id ? `/${store_id}` : ""}`,
            products: this.selected_group.map(({ title }) => title).join(","),
            project_domain: this.selected_group[0].project_domain,
            id: order_response.order_id,
            payment_method: data.payment_method
          }
          this.selected_group.forEach(product => this.remove_purchased_item_from_basket(product))
          this.update_groups(un_bind({
            ...this.basket_product_groups,
            [this.selected_group[0].project_id || current_store_key]: undefined
          }))
          this.update_selected_group(undefined)
        }

        if (payment_methods.paypal !== data.payment_method) this.update_temp_order_data({
          ...data,
          ...order_response,
        })
        if (order_link) this.update_order_links(order_link)
        else event_hub.$emit("user_has_payed", {
          ...data,
          ...order_response,
        })

      } catch (error) {
        console.log(error)
      }
      this.update_global_loader(false)
    }
  },
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_marketplace-constants" as *;
  @use "../../../../../styles/slide_in_animation" as *;

  .payment {
    position: relative;

    &__other-payment-method {
      text-align: center;
      padding: 0 20px;
      line-height: 45px;
      background-color: var(--background_heavy);
      color: var(--font_light);
      max-width: 300px;
      margin: 0 auto 10px;
      border-radius: 4px;
      cursor: pointer;
      transition: $default-transition;

      &:hover {
        filter: grayscale(30%);
      }

      &--disabled {
        cursor: default;
        opacity: 0.5;
      }
    }

    img {
      display: block;
      max-width: 100%;
      margin: $spacing--medium auto 0;
      max-height: 60px;
    }

    &__disabled {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--body_color);
      opacity: 0.5;
      top: 0;
      left: 0;
      cursor: not-allowed;
    }

    &__errors {
      color: $alert-color;
    }

    &__link {
      color: $link-color;
    }

    &__buttons {
      position: relative;
      z-index: -1;
    }

    &__shopify-button {
      margin-top: 10px;
    }

    &__paypal {
      position: relative;
      min-height: 100px;
      width: 100%;
      max-width: 300px;
      margin: auto;
    }

    &__loading {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(var(--body_color_RGB), 0.5);

     .loader-wave {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }
  }

  .slide-enter-active {
    animation: slide-in .3s;
  }
  .slide-leave-active {
    animation: slide-in .3s reverse;
  }
</style>
